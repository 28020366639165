<template>
    <label for="sms_attachment" class="switch_option capsule_btn">
        <h5>Switch to Upload A New File</h5>
        <input type="checkbox" v-model="fileAdd" id="sms_attachment" hidden>
        <div><span></span></div>
    </label>
    <div v-if="fileAdd" class="upload_image">
        <div :id="uniqueId" class="dropzone">
            <div class="dz-message" data-dz-message>
                <div>
                    <img src="@/assets/images/image2.png">
                    <h4>Click to Upload</h4>
                </div>
            </div>
        </div>
    </div>
    <div class="form_grp">
        <div class="group_item">
            <label class="input_label">Select From Master Files</label>
            <div class="field_wpr" :class="{ 'has-error': errors.file }">
                <Field autocomplete="off" name="file" v-model="form.file_ids" :class="{ 'has-error': errors.file }" rules="required">
                    <multiselect
                        v-model="form.file_ids"
                        :options="files"
                        value-prop="id"
                        label="name"
                        :searchable="true"
                        placeholder="Select file"
                        mode="tags"
                    >
                      <template v-slot:option="{ option }">
                          <img class="multiselect-img" :src="option.thumbnail" alt="img"> {{ option.name }}
                      </template>
                    </multiselect>
                </Field>
            </div>
            <ErrorMessage name="file" class="text-danger" />
        </div>
    </div>
    <ul v-if="isList" class="file_listing">
        <template v-for="(file, f) of contactFiles" :key="f">
            <li v-if="file && file.file">
                <div class="file_wpr">
                    <div class="file_thumb">
                        <img :src="file.file.thumbnail">
                        <button type="button" class="delete_btn" @click="handleDeleteFile(file.file, f)"><i class="fas fa-trash-alt"></i></button>
                        <button type="button" class="view_btn" @click="previewFile(file.file)"><i class="fas fa-binoculars"></i>View</button>
                    </div>
                    <div class="file_info">
                        <h4>{{file.file.name}}</h4>
                        <label :for="`file-portal-${f}`" class="switch_option capsule_btn">
                            <h5>Show in Portal</h5>
                            <input type="checkbox" @change="handlePortalPermission($event, file)" :checked="file.client_portal == 1" :id="`file-portal-${f}`" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
            </li>
        </template>
    </ul>
    <div v-if="filePreview" class="file_details" @click.self="filePreview = false">
        <file-preview :file="selectedFile" />
    </div>
</template>

<script>
    import Multiselect from '@vueform/multiselect'
    import FilePreview from '@/components/FilePreview'
    import Toastr from '@/utils/Toastr'
    import Helper from '@/utils/Helper'
    import Swal from 'sweetalert2'
    import Dropzone from 'dropzone'
    import 'dropzone/dist/dropzone.css'

    import { uuid } from 'vue-uuid'
    import { Field, ErrorMessage } from 'vee-validate'
    import { mapState, mapMutations, mapActions } from 'vuex'

    export default {
        name: 'File Component',

        data () {
            return {
                fileAdd: false,
                filePreview: false,
                form: {
                    contact_ids: '',
                    file_ids: [],
                },
                selectedFile: {},
                uniqueId: 'dropzone-'+uuid.v4(),
            }
        },

        props: {
            isList: Boolean,
            errors: [Object, Array],
            contacts: [String, Number],
            isBulkAction: {
                type: Boolean,
                default: false,
            }
        },

        emits: ['update:modelValue'],

        components: {
            Multiselect,
            FilePreview,
            Field,
            ErrorMessage,
        },

        computed: mapState({
            files: state => state.fileModule.allFiles,
            contactFiles: state => state.fileModule.contactFiles,
            fileLoader: state => state.fileModule.fileLoader,
            authToken: state => state.authModule.authToken,
        }),

        watch: {
            fileLoader (status) {
                if (status == false) {
                    const vm = this;

                    if (!vm.isBulkAction && vm.isList) {
                        vm.getContactFiles({ all: true, contact_id: vm.contacts });
                    }

                    vm.form = {
                                  contact_ids: vm.contacts,
                                  file_ids: [],
                              };

                    setTimeout(function () {
                        if (vm.$parent.$parent.resetForm) {
                            vm.$parent.$parent.resetForm();
                        } else {
                            vm.$parent.resetForm();
                        }
                    }, 10);
                }
            },

            fileAdd (val) {
                if (val) {
                    const vm = this;

                    setTimeout(function () {
                        vm.initDropzone();
                    }, 100);
                }
            },
        },

        mounted () {
            const vm = this;

            if (vm.files.length == 0) {
                vm.getAllFiles();
            }

            if (!vm.isBulkAction && vm.isList) {
                vm.getContactFiles({ all: true, contact_id: vm.contacts });
            }

            vm.form = {
                          contact_ids: vm.contacts,
                          file_ids: [],
                      };

            setTimeout(function () {
                if (vm.$parent.$parent.resetForm) {
                    vm.$parent.$parent.resetForm();
                } else {
                    vm.$parent.resetForm();
                }
            }, 10);
        },

        methods: {
            ...mapActions({
                getAllFiles: 'fileModule/getAllFiles',
                getContactFiles: 'fileModule/getContactFiles',
                validateFileName: 'fileModule/validateFileName',
                sendFile: 'fileModule/sendFile',
                uploadFile: 'fileModule/uploadFile',
                updatePermission: 'fileModule/updatePermission',
                deleteContactFiles: 'fileModule/deleteContactFiles',
            }),

            ...mapMutations({
                updateContactFiles: 'fileModule/UPDATE_CONTACT_FILES',
                removeContactFile: 'fileModule/REMOVE_CONTACT_FILE',
            }),

            initDropzone () {
                const vm = this;

                const dropzone = new Dropzone(`#${vm.uniqueId}`, {
                    url: process.env.VUE_APP_API_URL+'/api/v3/file/send-to-contact',
                    uploadMultiple: false,
                    autoProcessQueue: false,
                    paramName: 'images',
                    createImageThumbnails: true,
                    maxFiles:1,
                    init: function() {
                        this.hiddenFileInput.removeAttribute('multiple');
                    },
                    headers: {
                        'Authorization': 'Bearer '+vm.authToken.access_token,
                        'accessed-location-id': vm.authToken.accessed_location_id,
                        'company-admin-id': vm.authToken.company_admin_id,
                    },
                    // acceptedFiles: 'image/*',
                    addedfiles: function (files) {
                        const file  = files[0];
                        const form  = new FormData;

                        form.append('file', file);
                        form.append('contact_ids', vm.contacts);
                        form.append('filename', file.name);

                        vm.validateFileName({ name: file.name }).then((resp) => {
                            if (resp.data.status) {
                                const options = Helper.swalConfirmOptions('Are you sure?', `A file with the name ${file.name} already exists in your OM files. Add anyway?`, 'Yes', false);

                                Swal.fire(options).then((result) => {
                                    if (result.isConfirmed) {
                                        file.previewElement.classList.add("dz-processing");
                                        vm.uploadFile(form).then((f) => {
                                            if (f && vm.isBulkAction) {
                                                vm.updateContactFiles({ file: f });
                                            }

                                            if (vm.$parent.$parent.getContactFiles) {
                                                vm.$parent.$parent.getContactFiles(vm.$parent.$parent.params);
                                            }

                                            file.previewElement.classList.add("dz-success");
                                            file.previewElement.classList.add("dz-complete");
                                            const el = document.getElementById(`#${vm.uniqueId}`);

                                            if (el) {
                                                el.classList.remove('dz-started');
                                                el.classList.remove('dz-max-files-reached');
                                            }
                                        });
                                    } else {
                                        if (file.previewElement != null && file.previewElement.parentNode != null) {
                                            file.previewElement.parentNode.removeChild(file.previewElement);
                                            const el = document.getElementById(`#${vm.uniqueId}`);

                                            if (el) {
                                                el.classList.remove('dz-started');
                                                el.classList.remove('dz-max-files-reached');
                                            }
                                        }
                                    }
                                });

                            } else {
                                file.previewElement.classList.add("dz-processing");
                                vm.uploadFile(form).then((f) => {
                                    if (f && vm.isBulkAction) {
                                        vm.updateContactFiles({ file: f });
                                    }

                                    if (vm.$parent.$parent.getContactFiles) {
                                        vm.$parent.$parent.getContactFiles(vm.$parent.$parent.params);
                                    }

                                    file.previewElement.classList.add("dz-success");
                                    file.previewElement.classList.add("dz-complete");
                                    const el = document.getElementById(`#${vm.uniqueId}`);
                                    el.classList.remove('dz-started');
                                    el.classList.remove('dz-max-files-reached');
                                });
                            }
                        }).catch((err) => {
                            Toastr.handleServerError(err);
                        });
                    },
                });
            },

            fileSelected (files) {
                const vm    = this;
                const file  = files[0]
                const form  = new FormData;

                form.append('file', file);
                form.append('contact_ids', vm.contacts);
                form.append('filename', file.name);

                vm.validateFileName({ name: file.name }).then((resp) => {
                    if (resp.data.status) {
                        const options = Helper.swalConfirmOptions('Are you sure?', `A file with the name ${file.name} already exists in your OM files. Add anyway?`, 'Yes', false);

                        Swal.fire(options).then((result) => {
                            if (result.isConfirmed) {
                                vm.uploadFile(form).then((file) => {
                                    if (file && vm.isBulkAction) {
                                        vm.updateContactFiles({ file });
                                    }

                                    if (vm.$parent.$parent.getContactFiles) {
                                        vm.$parent.$parent.getContactFiles(vm.$parent.$parent.params);
                                    }

                                    document.getElementById('file').value = '';
                                });
                            } else {
                                document.getElementById('file').value = '';
                            }
                        });

                    } else {
                        vm.uploadFile(form).then((file) => {
                            if (file && vm.isBulkAction) {
                                vm.updateContactFiles({ file });
                            }

                            if (vm.$parent.$parent.getContactFiles) {
                                vm.$parent.$parent.getContactFiles(vm.$parent.$parent.params);
                            }

                            document.getElementById('file').value = '';
                        });
                    }
                }).catch((err) => {
                    Toastr.handleServerError(err);
                });
            },

            handleFileAdd () {
                const vm = this;

                return new Promise(function(resolve, reject) {
                    const files = vm.form.file_ids;

                    vm.sendFile(vm.form).then((result) => {
                        if (result && vm.isBulkAction) {
                            vm.files.forEach((file) => {
                                if (files.includes(file.id)) {
                                    vm.updateContactFiles({ file });
                                }
                            });
                        }

                        resolve(result);
                    });
                });
            },

            previewFile (file) {
                const vm = this;

                vm.selectedFile = file;
                vm.filePreview  = true;
            },

            handlePortalPermission (event, file) {
                const vm = this;
                const value = event.currentTarget.checked ? 1 : 0;

                vm.updatePermission({ permission: 'client_portal', value, id: file.file_id, contact_ids: [file.contact_id] });
            },

            handleDeleteFile (file, index) {
                const vm = this;

                vm.deleteContactFiles({ ids: [file.id], contact_ids: vm.contacts.toString().split(',') }).then((result) => {
                    if (result) {
                        vm.removeContactFile(index);
                    }
                });
            },
        },
    }
</script>

<style scoped>
    .dropzone {
        border: 1.2px dashed #95BFFF;
        background: #FAFCFD;
        min-height: 150px;
    }

    :deep(.dz-message) {
        vertical-align: middle;
        margin: auto;
        display: grid;
        height: 100%;
        min-height: 150px;
    }

    :deep(.dz-message div) {
        margin: auto;
    }
</style>
